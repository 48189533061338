<template>
  <div class="order-payment-info">
    <el-form
      label-width="150px"
      label-position="left"
    >
      <el-form-item v-if="paymentConditionType === 'deposit'" label="付款狀態">
        <Tag v-if="orderDepositData" style="display: inline-flex" :type="depositPaymentStatus(orderDepositData.status, 'tag')">{{ depositPaymentStatus(orderDepositData.status, 'label') }}</Tag>
      </el-form-item>
      <el-form-item label="訂單總額">
        {{ orderTotalPrice }}
      </el-form-item>
      <el-form-item :label="depositLabel">
        {{ depositTotalPrice }}
      </el-form-item>
      <el-form-item v-if="paymentConditionType === 'cardSecret' && orderStatus === 'absence'" label="扣款狀態">
        <Tag v-if="orderDepositData" style="display: inline-flex" :type="paymentStatus(orderDepositData.status, 'tag')">{{ paymentStatus(orderDepositData.status, 'label') }}</Tag>
      </el-form-item>
      <el-form-item v-if="paymentConditionType === 'cardSecret' && orderStatus === 'absence'" label="扣款日期">
        {{ paidAt }}
      </el-form-item>
      <el-form-item v-if="paymentConditionType === 'cardSecret' && ['complete', 'wait'].includes(orderStatus)" label="最晚可扣款日期">
        {{ lastPaymentDate }}
        <p v-if="showPaymentIntimeWarning" class="text-sm text-gray-80 leading-normal">提醒：若顧客當日無出席，請在預約時間後往後的一週內，將此筆訂單狀態改為「未到」，系統會向發卡銀行執行扣款。</p>
        <p v-if="showPaymentOvertimeWarning" class="text-sm text-gray-80 leading-normal">提醒：已過系統可向發卡銀行扣款的時間</p>
      </el-form-item>
      <el-form-item label="付款方式">
        {{ depositPaymentType }}
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'
import { formatDate, addDays } from '@/utils/date'
import { paymentStatusConfig, depositPaymnetStatusConfig } from '@/config/pubApt'
export default defineComponent({
  name: 'OrderPubAptPaymentBlock',
  props: {
    reservation: {
      type: Object,
      default: () => ({}),
    },
    index: Number,
  },
  setup (props) {
    const AppointmentVisitorPreOrder = computed(() => get(props.reservation, 'AppointmentOrder.AppointmentVisitorPreOrder'))
    const paymentConditionType = computed(() => get(AppointmentVisitorPreOrder.value, 'paymentConditionType')) // 'none', 'deposit', 'cardSecret'
    const start = computed(() => get(props.reservation, 'AppointmentOrder.start'))
    // 最晚可扣款日期 ＝ 預約時間 + 7天
    const lastPaymentDate = computed(() => addDays(start.value, 7))
    const orderTotalPrice = computed(() => {
      const price = get(props.reservation, 'AppointmentOrder.totalPrice')
      if (!price) return '-'
      return `$ ${price}`
    })
    const orderStatus = computed(() => get(props.reservation, 'AppointmentOrder.status'))
    const depositLabel = computed(() => paymentConditionType.value === 'deposit' ? '已付訂金總額' : '未到扣款金額')
    const orderDepositData = computed(() => {
      if (paymentConditionType.value === 'deposit') {
        return get(AppointmentVisitorPreOrder.value, 'DepositPubAptPaymentOrder')
      } else if (paymentConditionType.value === 'cardSecret') {
        return get(AppointmentVisitorPreOrder.value, 'PenaltyPubAptPaymentOrder') || get(AppointmentVisitorPreOrder.value, 'CardSecretPubAptPaymentOrder')
      }
      return null
    })
    const paidAt = computed(() => {
      if (orderDepositData.value && get(orderDepositData.value, 'status') !== 'pending') {
        return formatDate(get(orderDepositData.value, 'paidAt'))
      }
      return '-'
    })
    const depositPaymentType = computed(() => {
      if (orderDepositData.value) {
        return get(orderDepositData.value, 'PubAptPaymentConfig.displayName')
      }
      return get(AppointmentVisitorPreOrder.value, 'CardSecretPubAptPaymentOrder.PubAptPaymentConfig.displayName') || '-'
    })
    const depositTotalPrice = computed(() => {
      const paymentConditionAmount = get(AppointmentVisitorPreOrder.value, 'paymentConditionAmount')
      if (paymentConditionAmount) {
        return `$ ${paymentConditionAmount}`
      }
      return '-'
    })
    const showPaymentWarning = computed(() => {
      return paymentConditionType.value === 'cardSecret' && orderStatus.value !== 'wait'
    })
    const showPaymentIntimeWarning = computed(() => {
      return formatDate(new Date()) < lastPaymentDate.value && showPaymentWarning.value
    })
    const showPaymentOvertimeWarning = computed(() => {
      return formatDate(new Date()) > lastPaymentDate.value && showPaymentWarning.value
    })
    const paymentStatus = (status, attr) => {
      return get(paymentStatusConfig[status], attr)
    }
    const depositPaymentStatus = (status, attr) => {
      return get(depositPaymnetStatusConfig[status], attr)
    }
    return {
      paymentConditionType,
      lastPaymentDate,
      orderTotalPrice,
      orderDepositData,
      depositTotalPrice,
      depositPaymentType,
      depositLabel,
      showPaymentIntimeWarning,
      showPaymentOvertimeWarning,
      paymentStatus,
      depositPaymentStatus,
      orderStatus,
      paidAt,
    }
  },
  data: () => ({
    refundDialogType: 'wallet',
  }),
})
</script>

<style lang="postcss" scoped>
</style>
