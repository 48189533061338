<template>
  <div class="order-info">
    <el-form
      v-if="order"
      label-width="150px"
      label-position="left"
    >
      <!-- <p class="section-title text-primary-100 font-medium">{{ index }}.詳細資訊</p> -->
      <el-form-item label="操作">
        <el-button :disabled="isCancel" class="primary-btn underline" type="text" @click="showEditDialog = true">
          編輯詳細資訊
        </el-button>
      </el-form-item>
      <el-form-item label="預約單號">
        <div>{{ displayData.numberCode }}</div>
      </el-form-item>
      <el-form-item :label="isPubApt ? '訪客姓名' : '服務對象姓名'">
        <div>{{ displayData.userName }}</div>
      </el-form-item>
      <el-form-item :label="isPubApt ? '訪客電話' : '服務對象電話'">
        <div>{{ displayData.userPhone }}</div>
      </el-form-item>
      <el-form-item label="預約時間">
        <div>{{ dateFormat(displayData.start) }}</div>
      </el-form-item>
      <el-form-item label="服務項目">
        <div>{{ displayData.serviceName }}</div>
      </el-form-item>
      <el-form-item v-if="displayData.subServiceName" label="子項目">
        <div>{{ displayData.subServiceName }}</div>
      </el-form-item>
      <el-form-item label="服務人員">
        <div>{{ showNotSpecify(order) }}</div>
      </el-form-item>
      <el-form-item v-if="useServiceResource" label="服務設備">
        <div>{{ displayData.resourceItemName }}</div>
      </el-form-item>
      <el-form-item label="附加服務">
        <div>{{ attachServices }}</div>
      </el-form-item>
      <el-form-item label="建立時間">
        <div>{{ createdAt }}</div>
      </el-form-item>
      <!-- <el-form-item label="店家備註">
        <div>{{ order.remark || '無' }}</div>
      </el-form-item> -->
    </el-form>

    <EditReservationDialog
      v-if="showEditDialog"
      :isPubApt="isPubApt"
      :data="order"
      :usingClassTickets="syncData.classTickets"
      :disableFields="statusNotWait"
      @close="showEditDialog=false"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import dayjs from '@/lib/dayjs'
import EditReservationDialog from '@/components/Reservation/EditReservationDialog/EditReservationDialog.vue'
import { getData } from '@/utils/object'
import { map, get } from 'lodash'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'OrderDetailBlock',
  components: { EditReservationDialog },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    index: Number,
    syncData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const isPubApt = computed(() => get(props.order, 'AppointmentOrder.origin') === 'pubApt')
    const useServiceResource = computed(() => {
      return checkAction('admin.resourceItem.page')
    })
    const statusNotWait = computed(() => {
      return getData(props.order, 'AppointmentOrder.status') !== 'wait'
    })
    const displayData = computed(() => {
      const order = props.order
      const AppointmentVisitorPreOrder = get(order, 'AppointmentOrder.AppointmentVisitorPreOrder')
      const numberCode = get(order, 'numberCode', '')
      const userName = isPubApt.value ? get(AppointmentVisitorPreOrder, 'profile.lastName') + get(AppointmentVisitorPreOrder, 'profile.firstName') : get(order, 'userName', '未填寫')
      const userPhone = isPubApt.value ? `${get(AppointmentVisitorPreOrder, 'profile.phoneCountryCode')} ${get(AppointmentVisitorPreOrder, 'profile.phoneNumber')}` : get(order, 'userPhone', '未填寫')
      const start = get(order, 'start', null)
      const serviceName = get(order, 'AppointmentService.name', '')
      const subServiceName = get(order, 'AppointmentSubService.name', '')
      const resourceItemName = get(order, 'ResourceItem.name', '-')
      return {
        numberCode,
        userName,
        userPhone,
        start,
        serviceName,
        subServiceName,
        resourceItemName,
      }
    })
    return {
      useServiceResource,
      isPubApt,
      displayData,
      statusNotWait,
    }
  },
  data: () => ({
    showEditDialog: false,
  }),
  computed: {
    isCancel () {
      return getData(this.order, 'AppointmentOrder.status') === 'cancel'
    },
    peopleCount () {
      return getData(this.order, 'AppointmentOrder.peopleCount', 1)
    },
    orersCount () {
      const orders = getData(this.order, 'AppointmentOrder.AppointmentReservations', null)
      if (!orders) return 0
      return orders.length
    },
    attachServices () {
      const services = this.order.AppointmentServiceAttaches
      if (!services) return '-'
      const servicesName = map(services, 'name')
      if (!servicesName.length) return '-'
      return servicesName.join('、')
    },
    createdAt () {
      return this.dateFormat(this.order.createdAt)
    },
  },
  methods: {
    getData,
    statusContent (status) {
      const statusDict = {
        cancel: '已取消',
        wait: '已預約',
        complete: '完　成',
        absence: '未　到',
      }
      return statusDict[status] || 'N/A'
    },
    dateFormat (date) {
      if (!date) return '無'
      return dayjs(date).format('YYYY/MM/DD HH:mm:ss')
    },
    showNotSpecify (order) {
      const notSpecify = order.fromNotSpecify
      if (order.AppointmentUnit) return notSpecify ? `${order.AppointmentUnit?.name} (不指定)` : order.AppointmentUnit?.name
      else return '-'
    },
  },
})
</script>

<style scoped lang="scss">
</style>
