import { paymentOptionsConfig } from '@/config/payment'
import { get } from 'lodash'

export const usePayment = () => {
  const getDisplayPaymentType = ({ paidType, paidTypeComment }) => {
    let displayCheckoutPaidType
    if (paidType === 'offline') {
      displayCheckoutPaidType = get(paymentOptionsConfig, `${paidTypeComment}.name`, '-')
    } else {
      displayCheckoutPaidType = get(paymentOptionsConfig, `${paidType}.name`, '-')
    }
    return displayCheckoutPaidType
  }
  return { getDisplayPaymentType }
}
