<template>
  <el-dialog
    title="編輯訂單資訊"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form>
      <el-form-item label="訂單狀態" prop="status">
        <el-select
          v-model="formData.status"
          :disabled="isCancel"
          placeholder="請選擇狀態"
        >
          <el-option
            v-for="item in ['wait', 'complete', 'cancel', 'absence']"
            :key="item"
            :label="statusTranslate(item)"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="showPaymentWarning">
        <span class="warning">請注意此筆為授權信用卡的跨境預約訂單，將訂單狀態調整為未到後，系統將向顧客收取授權金額。</span>
      </el-form-item>
      <el-form-item v-if="useMemberAddress" prop="address">
        <AddressInput
          :data="get(data, 'AppointmentOrder.userAddress')"
          :city.sync="formData.address.city"
          :area.sync="formData.address.area"
          :detail.sync="formData.address.detail"
        />
      </el-form-item>
      <el-form-item label="店家筆記" prop="remark">
        <el-input
          v-model="formData.remark"
          type="textarea"
          :autosize="{ minRows: 8, maxRows: 8 }"
          placeholder="請輸入"
          maxlength="1000"
          show-word-limit
        />
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button
        ref="createBtn"
        type="primary"
        @click="updateOrder"
      >
        確認
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import AddressInput from '@/components/Input/AddressInput'
import {
  UpdateReservationOrderStatus,
  UpdateReservationOrderInfo,
} from '@/api/reservation'
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
import { statusTranslate } from '@/utils/helper'
import { get } from 'lodash'
import { formatDate, addDays } from '@/utils/date'

export default {
  name: 'EditReservationDialog',
  components: {
    AddressInput,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    formData: {
      status: '',
      remark: '',
      address: {
        city: '',
        area: '',
        detail: '',
      },
    },
  }),
  computed: {
    ...mapGetters([
      'shop',
      'userPlanLimit',
      'userFeatures',
      'userPlanFeature',
    ]),
    isCancel () {
      const status = get(this.data, 'AppointmentOrder.status', '')
      return status === 'cancel'
    },
    isAbsence () {
      const status = get(this.data, 'AppointmentOrder.status', '')
      return status === 'absence'
    },
    useMemberAddress () {
      return this.checkAction('adminView.member.findUserInfoMoreAddress')
    },
    isPubAptAndCardSecret () {
      return get(this.data, 'AppointmentOrder.origin') === 'pubApt' && get(this.data, 'AppointmentOrder.AppointmentVisitorPreOrder.paymentConditionType') === 'cardSecret'
    },
    showPaymentWarning () {
      const start = get(this.data, 'AppointmentOrder.start')
      const lastPaymentDate = addDays(start.value, 7)
      return formatDate(new Date()) < lastPaymentDate && this.isPubAptAndCardSecret && !this.isCancel && !this.isAbsence
    },
  },
  mounted () {
    this.formData.status = get(this.data, 'AppointmentOrder.status', '')
    this.formData.remark = get(this.data, 'AppointmentOrder.adminComment', '')
  },
  methods: {
    get,
    statusTranslate,
    checkAction (action) {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, action)
    },
    async updateOrderStatus () {
      if (this.isCancel) return ['ok', null]
      if (this.formData.status === get(this.data, 'AppointmentOrder.status', '')) return ['ok', null]
      const [, err] = await UpdateReservationOrderStatus({
        shopId: this.shop,
        id: get(this.data, 'AppointmentOrder.id', undefined),
        status: this.formData.status,
      })
      if (err) return [null, err]
      return ['ok', null]
    },

    async updateOrderInfo () {
      const { city, area, detail } = this.formData.address
      let userAddress
      if (this.useMemberAddress) userAddress = `${city}${area}${detail}`
      const [, err] = await UpdateReservationOrderInfo({
        shopId: this.shop,
        id: get(this.data, 'AppointmentOrder.id', undefined),
        adminComment: this.formData.remark,
        userAddress: userAddress || undefined,
      })
      if (err) return [null, err]
      return ['ok', null]
    },

    async updateOrder () {
      const [, statusUpdateErr] = await this.updateOrderStatus()
      if (statusUpdateErr) return this.$message.error(statusUpdateErr || statusUpdateErr.msg)
      const [, infoUpdateErr] = await this.updateOrderInfo()
      if (infoUpdateErr) return this.$message.error(infoUpdateErr || infoUpdateErr.msg)
      this.$message.success('更新成功')
      this.$emit('refresh')
      this.$emit('close')
    },
  },
}
</script>

<style lang="postcss" scoped>
.warning {
  @apply text-red-500 leading-[23.17px] inline-flex max-w-[556px]
}
</style>
