<template>
  <div class="order-payment-info">
    <p class="section-title text-primary-100 font-medium">訂金付款資訊</p>
    <el-form label-width="150px" label-position="left">
      <!-- <el-form-item label="付款狀態">
        <div>
          <Tag style="display: inline-flex" :type="tagType(orderDepositData.status)">
            {{ paymentStatus }}
          </Tag>
          <el-button v-if="showRefundBtn" type="text" class="refund-btn" @click="goRefund">
            前往退款
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="應付訂金">
        {{ depositTotalPrice }}
      </el-form-item>
      <el-form-item v-if="orderDepositData.status === 'refund'" label="退款金額">
        <span v-if="orderDepositData.refundPrice">$ {{ orderDepositData.refundPrice }}</span>
        <span v-else>-</span>
      </el-form-item>
      <el-form-item label="付款方式">
        {{ depositPaymentType }}
      </el-form-item>
      <el-form-item v-if="showPaymentCode" label="交易序號">
        <div class="flex items-center" style="gap: 14px">
          <p>{{ orderDepositData.code }}</p>
          <div class="flex items-center" @click="copyPaymentCode">
            <MaterialIcon color="var(--primary-100)" size="24">content_copy_filled</MaterialIcon>
          </div>
        </div>
      </el-form-item> -->

      <el-form-item v-for="struct in dataStruct" :key="struct.key" :label="struct.label">
        <div v-if="struct.items" class="flex items-center" :style="`gap: ${struct.itemsGap}px; min-height: 40px`">
          <template v-for="(item, idx) in struct.items">
            <p v-if="item.el === 'p'" :key="`item-${idx}`">
              {{ item.label }}
            </p>
            <Tag
              v-if="item.el === 'tag'"
              :key="`item-${idx}`"
              class="inline-flex"
              v-bind="item.props"
            >
              {{ item.label }}
            </Tag>

            <div
              v-if="item.el === 'materialIcon'"
              :key="`item-${idx}`"
              class="cursor-pointer"
              v-on="item.on"
            >
              <MaterialIcon color="var(--primary-100)" size="24">
                {{ item.icon }}
              </MaterialIcon>
            </div>

            <el-button
              v-if="item.el === 'el-button' && item.if"
              :key="`item-${idx}`"
              v-bind="item.props"
              v-on="item.on"
            >
              前往退款
            </el-button>
          </template>
        </div>
      </el-form-item>
    </el-form>

    <div v-if="showMarkRefundBtn" class="flex justify-end">
      <el-button plain @click="showMarkRefundDialog = true">標記已退款</el-button>
    </div>

    <DepositRefundDialog
      v-if="showRefundDialog"
      :type="refundDialogType"
      :isManual="manualRefund"
      :orderId="orderData.id"
      :payment="{
        type: orderDepositData.paidType,
        comment: orderDepositData.paidTypeComment,
      }"
      @refunded="$emit('refresh')"
      @close="showRefundDialog = false"
    />
    <MarkRefundDialog
      v-if="showMarkRefundDialog"
      :orderData="orderData"
      :data="refundData"
      :maxPrice="orderDepositData.depositPrice || null"
      chargeType="deposit"
      @marked="$emit('refresh')"
      @close="showMarkRefundDialog = false"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import copy from 'clipboard-copy'
import { get, includes, filter } from 'lodash'
import { depositPaymentTypes, depositStatusConfig } from '@/config/deposit'
import DepositRefundDialog from './DepositRefundDialog.vue'
import MarkRefundDialog from './MarkRefundDialog.vue'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'OrderPaymentBlock',
  components: {
    DepositRefundDialog,
    MarkRefundDialog,
  },
  props: {
    reservation: {
      type: Object,
      default: () => ({}),
    },
    index: Number,
  },
  setup (props) {
    const manualRefund = ref(true)
    const showRefundDialog = ref(false)
    const refundDialogType = ref('wallet')
    const showMarkRefundDialog = ref(false)

    const refundData = computed(() => ({
      refundType: get(orderDepositData.value, 'paidType'),
      refundTypeComment: get(orderDepositData.value, 'paidTypeComment'),
    }))

    const orderData = computed(() => get(props.reservation, 'AppointmentOrder') || {})

    const orderTotalPrice = computed(() => {
      const price = orderData.value.totalPrice
      if (!price) return '-'
      return `$ ${price}`
    })

    const orderDepositData = computed(() => get(props.reservation, 'AppointmentOrder.AppointmentOrderDeposit'))

    const depositTotalPrice = computed(() => {
      const price = orderDepositData.value.depositPrice
      if (!price) return '-'
      return `$ ${price}`
    })

    const depositPaymentType = computed(() => {
      const type = orderDepositData.value.paidType
      if (type === 'offline') {
        const comment = orderDepositData.value.paidTypeComment
        return get(depositPaymentTypes[comment], 'name')
      }
      return get(depositPaymentTypes[type], 'name')
    })

    const paymentStatus = computed(() => {
      const status = orderDepositData.value.status
      return get(depositStatusConfig[status], 'name')
    })

    const showRefundBtn = computed(() => {
      const status = orderDepositData.value.status
      const paymentType = orderDepositData.value.paidType
      const allowType = ['jkos', 'linepay', 'newebpay', 'wallet']
      if (status === 'waitRefund') {
        if (allowType.includes(paymentType)) return true
      }
      return false
    })

    const showMarkRefundBtn = computed(() => {
      const status = orderDepositData.value.status
      const paymentType = orderDepositData.value.paidType
      if (paymentType === 'wallet' && status === 'waitRefund') return false
      if (status === 'waitRefund') return true
      return false
    })

    const showPaymentCode = computed(() => {
      const paymentType = orderDepositData.value.paidType
      if (paymentType === 'offline') return false
      if (paymentType === 'free') return false
      const payment = depositPaymentTypes[paymentType]
      if (payment.type === 'third-part') return true
      return false
    })

    const goRefund = () => {
      const type = orderDepositData.value.paidType
      const other = ['jkos', 'newebpay', 'linepay']
      if (type === 'wallet') refundDialogType.value = 'wallet'
      if (other.includes(type)) refundDialogType.value = 'other'
      if (type.includes('offline')) refundDialogType.value = 'mark'
      showRefundDialog.value = true
    }

    const tagType = (status) => get(depositStatusConfig[status], 'tag')

    const copyPaymentCode = () => {
      copy(orderDepositData.value.code)
      window.$message.success(notifyMessage.copySuccess)
    }

    const isBatchOrder = computed(() => {
      return get(props.reservation, 'AppointmentOrder.AppointmentBatchOrder')
    })

    const dataStruct = computed(() => {
      // 所有欄位與資料
      const data = [
        {
          label: '付款狀態',
          key: 'status',
          itemsGap: 8,
          items: [
            {
              el: 'tag',
              props: {
                type: tagType(orderDepositData.value.status),
                disableTransitions: true,
              },
              label: paymentStatus.value,
            },
            {
              el: 'el-button',
              props: { type: 'text', class: 'refund-btn underline' },
              if: showRefundBtn.value,
              on: {
                click: goRefund,
              },
              label: '前往退款',
            },
          ],
        },
        {
          label: '應付訂金',
          key: 'totalItemsPrice',
          items: [
            { el: 'p', label: depositTotalPrice.value },
          ],
        },
        {
          label: '退款金額',
          key: 'refundPrice',
          items: [
            {
              el: 'p',
              label: orderDepositData.value.refundPrice ? `$ ${orderDepositData.value.refundPrice}` : '-',
            },
          ],
        },
        {
          label: '付款方式',
          key: 'paidType',
          items: [
            { el: 'p', label: depositPaymentType.value },
          ],
        },
        {
          label: '交易序號',
          key: 'orderCode',
          itemsGap: 8,
          items: [
            {
              el: 'p',
              label: orderDepositData.value.code,
            },
            {
              el: 'materialIcon',
              icon: 'content_copy_filled',
              on: {
                click: () => {
                  try {
                    copy(orderDepositData.value.code)
                    window.$message.success(notifyMessage.copySuccess)
                  } catch (error) {
                    window.$message.error(error)
                  }
                },
              },
            },
          ],
        },
      ]

      // 依據條件顯示不同的欄位
      if (isBatchOrder.value) {
        return filter(data, (item) => includes(['totalItemsPrice'], item.key))
      }
      return data
    })

    return {
      dataStruct,
      manualRefund,
      showRefundDialog,
      refundDialogType,
      showMarkRefundDialog,
      refundData,
      orderData,
      orderTotalPrice,
      orderDepositData,
      depositTotalPrice,
      depositPaymentType,
      paymentStatus,
      showRefundBtn,
      showMarkRefundBtn,
      showPaymentCode,
      goRefund,
      tagType,
      copyPaymentCode,
    }
  },
})
</script>

<style lang="postcss" scoped>
.refund-btn {
  @apply ml-[11px];
}
</style>
