<template>
  <div v-if="showCustomFlow" class="flex gap-[8px]">
    <el-select
      v-model="syncData.customFlow"
      class="test"
      clearable
      placeholder="自定義流程"
      @change="handleCustomFlowChange"
      @clear="refresh(true)"
    >
      <el-option
        v-for="item in displayCustomFlowConfig"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      />
    </el-select>
    <el-select
      v-model="syncData.customFlowId"
      class="test"
      clearable
      placeholder="流程狀態"
      :disabled="syncData.customFlow === ''"
      @change="refresh(true)"
      @clear="refresh(true)"
    >
      <el-option
        v-for="item in customFlowList"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      />
    </el-select>
  </div>
</template>

<script>
import { computed } from 'vue'
import { get, set, find } from 'lodash'
import { useVModel } from '@vueuse/core'

export default {
  props: ['showCustomFlow', 'search', 'displayCustomFlowConfig'],
  setup (props, { emit }) {
    const syncData = useVModel(props, 'search', emit)
    const handleCustomFlowChange = () => {
      set(syncData, 'value.customFlowId', null)
      refresh()
    }
    const customFlowList = computed(() => {
      return get(find(props.displayCustomFlowConfig, { id: get(syncData, 'value.customFlow') }), 'nodes')
    })

    const refresh = () => {
      console.log(syncData.value)
      emit('refresh', syncData.value)
    }

    return {
      handleCustomFlowChange,
      customFlowList,
      syncData,
      refresh,
    }
  },
}
</script>
