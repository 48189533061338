import { render, staticRenderFns } from "./ClassTicketDetailBlock.vue?vue&type=template&id=50a0b50c&scoped=true"
import script from "./ClassTicketDetailBlock.vue?vue&type=script&lang=js"
export * from "./ClassTicketDetailBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50a0b50c",
  null
  
)

export default component.exports