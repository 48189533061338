<template>
  <div>
    <el-table-column
      v-for="customFlow in displayColumn"
      :key="customFlow.id"
      :label="customFlow.name"
      align="center"
      :column-key="customFlow.id"
    >
      <template slot-scope="scope">
        <!-- <Tag type="action">{{ customFlow.name }}</Tag> -->
        <Tag :type="statusName(customFlow.id, scope.row) !=='-'? 'action' :'info'">{{ statusName(customFlow.id, scope.row) }}</Tag>
      </template>
    </el-table-column>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { showColumn } from '@/utils/tableFilter'
import { filter, get, find } from 'lodash'
import { orderCustomFlowRecordPathConfig } from '@/config/customOrderStatus'

export default defineComponent({
  name: 'CustomFlowTableColumns',
  props: {
    customFlowConfig: { type: Array, default: () => [] },
    tableFilterConfig: { type: Array, default: () => [] },
    scope: String,
    noFilter: Boolean,
  },
  setup (props) {
    const displayColumn = computed(() => {
      return filter(props.customFlowConfig, (flow) => {
        if (!props.noFilter) return flow.enable && showColumn(flow.name, props.tableFilterConfig)
        return flow.enable
      })
    })

    const statusName = computed(() => {
      return (flowId, rowData) => {
        const statusRecordList = get(rowData, get(orderCustomFlowRecordPathConfig, props.scope))
        return get(find(statusRecordList, { CustomFlowId: flowId }), 'nodeName') || '-'
      }
    })
    return { displayColumn, statusName }
  },
})
</script>

<style lang="postcss" scoped>

</style>
