<template>
  <el-drawer class="reservation-order-history" size="600px" title="查看付款明細" :visible="show" @close="onClose">
    <div class="content-wrapper">
      <p class="pb-[12px] font-medium">結帳明細</p>
      <ReservationCheckoutReceipt :data="orderCheckoutData" class="text-sub" />
      <div class="row">
        <p>實付金額</p>
        <p>$ {{ displayData.checkoutPrice }}</p>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import ReservationCheckoutReceipt from '@/components/Reservation/ReservationCheckoutReceipt/ReservationCheckoutReceipt.vue'
import { get, toNumber } from 'lodash'

export default defineComponent({
  name: 'OrderCheckoutReceiptDrawer',
  components: { ReservationCheckoutReceipt },
  props: {
    orderCheckoutData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const show = ref(true)
    const onClose = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }
    const displayData = computed(() => {
      return {
        checkoutPrice: toNumber(get(props.orderCheckoutData, 'totalPrice')).toLocaleString(),
      }
    })
    return { onClose, show, displayData }
  },
})
</script>

<style lang="postcss" scoped>
.content-wrapper {
  @apply px-[24px] pb-[100px];
}

.row {
  @apply flex justify-between items-center text-sub pt-[12px];
}
</style>
